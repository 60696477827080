/**
 * Created by vaibhav on 31/3/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import IRPageWrapper from "../../../layouts/IRPageWrapper"
import C17 from "./calendarimg/2017-23rd.jpg"
import C18 from "./calendarimg/2017-2.jpg"
import CalendarNav from "../../../components/CalendarNav"
import IRBG from "../../../components/IRBG"
import HomeLogo from "../../../components/HomeLogo"

import IRNav from "../../../components/IRNav"

export default class CalendarPage extends Component {
  render() {
    return (
      <IRPageWrapper>
        <IRBG />
        <Helmet>
          <title>Calendar - EA Technique</title>
        </Helmet>
        <section className="hero is-small">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <div className="section">
                    <HomeLogo />
                    <h1 className="title" id="title-line">
                      Corporate Calendar
                    </h1>
                    <IRNav />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <CalendarNav />
          <div className="column is-offset-2">
            <div className="columns" id="calendar-line">
              <div className="column is-1">
                <img src={C17} />
              </div>
              <div className="column">
                <h1 className="subtitle">
                  23rd Annual General Meeting ("AGM") of E.A. Technique (M)
                  Berhad
                </h1>
                <p>
                  Date of Meeting : 17 May 2017
                  <br />
                  Time : 11:00 AM
                  <br />
                  Venue : Tanjung Puteri 302,
                  <br />
                  Persada Johor International Convention Centre
                  <br />
                  Jalan Abdullah Ibrahim,
                  <br />
                  80000 Johor Bahru, Johor
                </p>
              </div>
            </div>
          </div>
          <div className="column is-offset-2">
            <div className="columns">
              <div className="column is-1">
                <img src={C18} />
              </div>
              <div className="column">
                <h1 className="subtitle">
                  Extraordinary General Meeting ("EGM") of E.A. Technique (M)
                  Berhad
                </h1>
                <p>
                  Date of Meeting : 20 Mar 2017
                  <br />
                  Time : 12:00 PM
                  <br />
                  Venue : Permata Ballroom, Level B2, The Puteri Pacific Johor
                  Bahru, Jalan Abdullah Ibrahim, 80000 Johor Bahru, Johor
                  <br />
                  Date of General Meeting Record of Depositors : 13 Mar 2017
                </p>
              </div>
            </div>
          </div>
        </section>
      </IRPageWrapper>
    )
  }
}

CalendarPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}
